import { SyncOutlined } from '@ant-design/icons'
import { Button, Card, Icon, Input, Popover, Switch, TimePicker } from 'antd'
import { API } from 'aws-amplify'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import { useDispatch, useSelector } from 'react-redux'
import { BodyText, H4, H3 } from 'src/branding/typography'
import HeaderScreen from 'src/components/shared/header-screen/HeaderScreen'
import FadeIn from 'src/components/styled/FadeIn'
import { editVenue, updateVenueCode } from 'src/features/VenueSlice'
import { editVenueSuppliers } from 'src/features/VenueSuppliersSlice'
import { SuccessNotification } from 'src/notifications/SuccessNotification'
import styled from 'styled-components/macro'
import _ from 'underscore'

const Settings = () => {
  const dispatch = useDispatch()
  const account = useSelector(state => state.auth.account)
  const venue = useSelector(state => state.venue.venue)
  const { isAdmin } = account
  const isTFRAdmin = account && account.TFRAdmin
  const content = (
    <div>
      <p style={{ maxWidth: 320 }}>
        Go to your website and right click (on Windows) or control + click (on
        Mac) over your logo and select &apos;Open image in a new tab&apos;. Then
        copy the web address (URL) of the new tab and paste it below.
      </p>
      <p style={{ maxWidth: 320 }}>Let us know if you need more help!</p>
    </div>
  )
  const {
    _id: venueId = '',
    addressLine1 = '',
    addressLine2 = '',
    addressCity = '',
    addressPostCode = '',
    TFRVenue: TFRVenueFromRedux = '',
    isPriceChangesVisible: isPriceChangesVisibleFromRedux = '',
    isPricesHidden: isPricesHiddenFromRedux = '',
    isInvoices: isInvoicesFromRedux = '',
    dataplusIntegration: isDataplusIntegrationFromRedux = '',
    isAddSupplierButtonHidden: isAddSupplierButtonHiddenFromRedux = '',
    isAccountNumberEditable: isAccountNumberEditableFromRedux = '',
    orderReminder: orderReminderFromRedux = '',
    phoneNumber: phoneNumberFromRedux = '',
    venueLogo: venueLogoFromRedux = '',
    notificationTime: notificationTimeFromRedux = +new Date(),
    title: titleFromRedux = '',
    code: venueCodeFromRedux = '',
    statsReciveEmails: statsReciveEmailsFromRedux = [],
    additionalChatSupport: additionalChatSupportFromRedux = {
      title: '',
      emailAddress: '',
    },

    // venueDeliveryDays: venueDeliveryDaysFromVenue = {},
  } = venue || {}

  const hasAllSettings =
    addressLine1 && addressLine2 && addressCity && addressPostCode

  const addressFromServer = {
    addressLine1,
    addressLine2,
    addressCity,
    addressPostCode,
  }

  const [address, setAddress] = useState({ ...addressFromServer })
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberFromRedux)
  const [title, setTitle] = useState(titleFromRedux)
  const [venueLogo, setVenueLogo] = useState(venueLogoFromRedux)
  const [code, setCode] = useState(venueCodeFromRedux)
  const [TFRVenue, setTFRVenue] = useState(TFRVenueFromRedux)
  const [isInvoices, setIsInvoices] = useState(isInvoicesFromRedux)
  const [statsReciveEmails, setStatsRecieveEmails] = useState(
    statsReciveEmailsFromRedux,
  )
  const [dataplusIntegration, setDataplusIntegration] = useState(
    isDataplusIntegrationFromRedux,
  )
  const [isPriceChangesVisible, setIsPriceChangesVisible] = useState(
    isPriceChangesVisibleFromRedux,
  )

  console.log('{statsReciveEmails', statsReciveEmails)
  const [isPricesHidden, setIsPricesHidden] = useState(isPricesHiddenFromRedux)
  const [isAccountNumberEditable, setIsAccountNumberEditable] = useState(
    isAccountNumberEditableFromRedux,
  )
  const [isAddSupplierButtonHidden, setIsAddSupplierButtonHidden] = useState(
    isAddSupplierButtonHiddenFromRedux,
  )
  const [orderReminder, setorderReminder] = useState(orderReminderFromRedux)
  const [notificationTime, setNotificationTime] = useState(
    notificationTimeFromRedux,
  )
  const [additionalChatSupport, setAdditionalChatSupport] = useState({
    ...additionalChatSupportFromRedux,
  })
  // const [venueDeliveryDays, setVenueDeliveryDays] = useState(
  //   venueDeliveryDaysFromVenue,
  // )

  useEffect(() => {
    setCode(venueCodeFromRedux)
  }, [venueCodeFromRedux])

  const updateAddress = (param, value) => {
    setAddress({
      ...address,
      [param]: value,
    })
  }

  // const updateDeliveryDays = param => {
  //   const venueDeliveryDaysCopy = { ...venueDeliveryDays }
  //   venueDeliveryDaysCopy[param] = !venueDeliveryDaysCopy[param]
  //   setVenueDeliveryDays(venueDeliveryDaysCopy)
  // }

  const submit = () => {
    // eslint-disable-next-line no-unused-vars
    const { addressLine2, ...rest } = address
    const isEmptyAddress = Object.values(rest).some(
      item => item === null || item === '',
    )

    if (isEmptyAddress) {
      alert('Please complete your address')
      return
    }

    const isEmptyPhone = !phoneNumber
    if (isEmptyPhone) {
      alert('Please complete your phone number')
      return
    }

    const isEmptyTitle = !title
    if (isEmptyTitle) {
      alert('Please complete your venue title')
      return
    }

    const isEmptyCode = code.toString().length < 6
    if (isEmptyCode) {
      alert('The venue code has to have at least 6 characters')
      return
    }
    // if (!_.isEqual(venueDeliveryDays, venueDeliveryDaysFromVenue)) {
    //   dispatch(
    //     editVenueSuppliers({
    //       venue: venueId,
    //       hasVenueUpdateDeliveryDays: true,
    //     }),
    //   )
    // }

    if (hasAllSettings && !_.isEqual(address, addressFromServer)) {
      dispatch(
        editVenueSuppliers({
          venue: venueId,
          hasVenueUpdateAddress: true,
        }),
      )
    }

    dispatch(
      editVenue(
        {
          phoneNumber,
          title,
          notificationTime,
          code,
          venueLogo,
          additionalChatSupport,
          TFRVenue,
          isPriceChangesVisible,
          orderReminder,
          isPricesHidden,
          isInvoices,
          dataplusIntegration,
          isAddSupplierButtonHidden,
          isAccountNumberEditable,
          statsReciveEmails,
          // venueDeliveryDays,
          ...address,
        },
        venueId,
      ),
    )
  }

  const refreshVenueCode = () => dispatch(updateVenueCode(venueId))

  // const refreshAdminVenueCode = () => dispatch(updateAdminVenueCode(venueId))

  const getEmail = async () => {
    try {
      console.log('venueId---> ', venueId)
      await API.get('api', `getWeeklyInvoice/${venueId}`)
      SuccessNotification({
        message: 'Venue Statistics',
        description:
          'Statistics have been successfully sent to the provided emails.',
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Wrapper>
      <HeaderScreen
        title={'Settings'}
        subTitle={'Update your venue and delivery details'}
      ></HeaderScreen>
      {venue && (
        <Fragment>
          <DivFlex>
            <CardSide1Wrapper>
              <CardColumn>
                <Element>
                  <H4>Venue name</H4>
                  <Input
                    placeholder='Venue name'
                    value={title}
                    onChange={event => {
                      setTitle(event.target.value)
                    }}
                  />
                </Element>
                <Element>
                  <H4>Contact number</H4>
                  <Input
                    type='number'
                    placeholder='Phone Number'
                    value={phoneNumber}
                    onChange={event => setPhoneNumber(event.target.value)}
                  />
                </Element>
                <Element>
                  <H4>Venue address</H4>
                  <Input
                    placeholder='Address line 1'
                    value={address.addressLine1}
                    onChange={event => {
                      updateAddress(`addressLine1`, event.target.value)
                    }}
                  />

                  <Input
                    placeholder='Address line 2'
                    value={address.addressLine2}
                    onChange={event => {
                      updateAddress(`addressLine2`, event.target.value)
                    }}
                  />

                  <Input
                    placeholder='Address city'
                    value={address.addressCity}
                    onChange={event => {
                      updateAddress(`addressCity`, event.target.value)
                    }}
                  />

                  <Input
                    placeholder='Address post code'
                    value={address.addressPostCode}
                    onChange={event => {
                      updateAddress(`addressPostCode`, event.target.value)
                    }}
                  />
                </Element>
              </CardColumn>
              <CardColumn>
                <Element>
                  <H3>Manage</H3>
                  {isAdmin && (
                    <Row>
                      <SwitchSpan>TFR</SwitchSpan>
                      <SwitchComp
                        value={TFRVenue}
                        onChange={event => {
                          setTFRVenue(event)
                        }}
                        checked={TFRVenue}
                      />
                    </Row>
                  )}
                  {isAdmin && (
                    <Row>
                      <SwitchSpan>Invoices Made Simple</SwitchSpan>
                      <SwitchComp
                        value={isInvoices}
                        onChange={event => {
                          setIsInvoices(event)
                        }}
                        checked={isInvoices}
                      />
                    </Row>
                  )}
                  {isAdmin && (
                    <Row>
                      <SwitchSpan>Dataplus integration</SwitchSpan>
                      <SwitchComp
                        value={dataplusIntegration}
                        onChange={event => {
                          setDataplusIntegration(event)
                        }}
                        checked={dataplusIntegration}
                      />
                    </Row>
                  )}
                  {isAdmin && (
                    <Row>
                      <SwitchSpan>Is prices hidden</SwitchSpan>
                      <SwitchComp
                        value={isPricesHidden}
                        onChange={event => {
                          setIsPricesHidden(event)
                        }}
                        checked={isPricesHidden}
                      />
                    </Row>
                  )}
                  {isAdmin && (
                    <Row>
                      <SwitchSpan>{`Is "Add supplier" button hidden`}</SwitchSpan>
                      <SwitchComp
                        value={isAddSupplierButtonHidden}
                        onChange={event => {
                          setIsAddSupplierButtonHidden(event)
                        }}
                        checked={isAddSupplierButtonHidden}
                      />
                    </Row>
                  )}
                  <Row>
                    <SwitchSpan>Is prices changes visible</SwitchSpan>
                    <SwitchComp
                      value={isPriceChangesVisible}
                      onChange={event => {
                        setIsPriceChangesVisible(event)
                      }}
                      checked={isPriceChangesVisible}
                    />
                  </Row>
                  <Row>
                    <SwitchSpan>Send order reminder</SwitchSpan>
                    <SwitchComp
                      value={orderReminder}
                      onChange={event => {
                        setorderReminder(event)
                      }}
                      checked={orderReminder}
                    />
                  </Row>
                  <Row>
                    <SwitchSpan>{`Is account number editable in app`}</SwitchSpan>
                    <SwitchComp
                      value={isAccountNumberEditable}
                      onChange={event => {
                        setIsAccountNumberEditable(event)
                      }}
                      checked={isAccountNumberEditable}
                    />
                  </Row>
                  {isInvoicesFromRedux && (
                    <Row>
                      <SaveButton>
                        <Button type='primary' onClick={getEmail}>
                          <span>Send OMS Email</span>
                        </Button>
                      </SaveButton>
                    </Row>
                  )}
                </Element>
              </CardColumn>
            </CardSide1Wrapper>
            <Card2Wrapper>
              <CardColumn>
                <DivFlex>
                  <Element>
                    <DivFlex>
                      <H4>Order reminder</H4>
                    </DivFlex>
                    <p>
                      <BodyText>
                        We will send you a handy reminder to send your orders
                        before this time, so you don’t miss your order cut off
                        time.
                      </BodyText>
                    </p>
                    <TimePicker
                      size={'large'}
                      format={'HH:mm'}
                      value={moment(notificationTime)}
                      allowClear={false}
                      onChange={event => setNotificationTime(event.toDate())}
                    />
                  </Element>
                  <DivImg>
                    <Img src={venue.venueLogo} alt='' />
                  </DivImg>
                </DivFlex>
                <Element>
                  <H4>App access </H4>
                  <p>
                    <BodyText>
                      Venue codes can be used to access the ordering app
                    </BodyText>
                  </p>
                  <FieldSet>
                    <Legend>Venue code</Legend>
                    <CodeDiv>
                      {isAdmin || isTFRAdmin ? (
                        <Input
                          type='text'
                          value={code}
                          onChange={event => setCode(event.target.value)}
                        />
                      ) : (
                        venueCodeFromRedux
                      )}
                    </CodeDiv>

                    <ButtonDiv
                      onClick={() => {
                        refreshVenueCode()
                      }}
                    >
                      <SyncOutlined />
                    </ButtonDiv>
                  </FieldSet>
                  {/* <AdminFieldSet>
                    <AdminCodeLegend>Admin Venue Code</AdminCodeLegend>
                    <CodeDiv>{venue.adminCode}</CodeDiv>
                    <ButtonDiv
                      onClick={() => {
                        refreshAdminVenueCode()
                      }}
                    >
                      <SyncOutlined />
                    </ButtonDiv>
                  </AdminFieldSet>
                  <p>
                    <BodyText>
                      For users that need full access to app features
                    </BodyText>
                  </p> */}
                </Element>
                {isAdmin || venue?.TFRVenue ? (
                  <Element>
                    <H4>Additional app chat support </H4>
                    <Input
                      placeholder='Company support name'
                      value={additionalChatSupport.title}
                      onChange={event => {
                        setAdditionalChatSupport({
                          ...additionalChatSupport,
                          title: event.target.value,
                        })
                      }}
                    />

                    <Input
                      placeholder='Email address'
                      value={additionalChatSupport.emailAddress}
                      onChange={event => {
                        setAdditionalChatSupport({
                          ...additionalChatSupport,
                          emailAddress: event.target.value,
                        })
                      }}
                    />
                  </Element>
                ) : null}
                {/* <Element>
                  <H4>Delivery days</H4>
                  <p>
                    <BodyText>
                      These will appear on your order checklists to suppliers,
                      so they know when to schedule your delivery.
                    </BodyText>
                  </p>
                  <Fragment>
                    {Object.entries(venueDeliveryDays).map(([day, value]) => (
                      <DayButton
                        key={day}
                        value={value}
                        onClick={event => {
                          updateDeliveryDays(day)
                          event.target.blur()
                        }}
                        isgreen={value ? 1 : 0}
                      >
                        {wordCaps(day).slice(0, 3)}
                      </DayButton>
                    ))}
                  </Fragment>
                </Element> */}

                <Element>
                  <DivFlex>
                    <H4>Your logo </H4>
                    <Popover
                      placement='topLeft'
                      content={content}
                      title={"You can now upload your venue's logo"}
                    >
                      <Icon style={{ marginTop: 2 }} type='question-circle' />
                    </Popover>
                  </DivFlex>
                  <Input
                    type='text'
                    placeholder="Logo's URL"
                    value={venueLogo}
                    onChange={event => setVenueLogo(event.target.value)}
                  />
                </Element>
                {isInvoices && (
                  <Element>
                    <DivFlex>
                      <H4>Stats Email Send To</H4>
                    </DivFlex>
                    <ReactMultiEmail
                      placeholder=''
                      emails={statsReciveEmails}
                      style={{ margin: '0px' }}
                      onChange={_emails => {
                        console.log({ _emails })
                        setStatsRecieveEmails(_emails)
                      }}
                      validateEmail={
                        email => isEmail(email) // return boolean
                      }
                      getLabel={(email, index, removeEmail) => (
                        <InnerDiv data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </InnerDiv>
                      )}
                    />
                  </Element>
                )}
                <SaveButton>
                  <Button type='primary' onClick={submit}>
                    <span>Save</span>
                  </Button>
                </SaveButton>
              </CardColumn>
            </Card2Wrapper>
          </DivFlex>
        </Fragment>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)`
  > div {
    margin-bottom: 15px;

    input {
      margin-bottom: 10px;
    }

    .react-multi-email {
      input {
        margin-bottom: 0px;
      }
    }

    input {
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
`

const Element = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  .ant-time-picker-icon {
    top: 35%;
  }
`

const CardColumn = styled(Card)`
  margin-bottom: 4% !important;
  display: flex;
  flex-direction: column;
`

const CardSide1Wrapper = styled.div`
  width: 30%;
  //> div {
  //  height: 700px;
  //}
`
const Card2Wrapper = styled.div`
  width: 70%;
  //> div {
  //  height: 700px;
  //}
`
const CodeDiv = styled.div`
  margin-left: 10px;
`
const ButtonDiv = styled.a`
  margin: 0 10px 20px 0;
`
const SwitchComp = styled(Switch)``

const SwitchSpan = styled.span`
  font-weight: bold;
  display: inline-block;
  margin-right: 7px;
`
const Row = styled.div`
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
`
// const DayButton = styled(Button)`
//   background-color: ${({ isgreen, theme }) =>
//     isgreen ? theme.colours.avocadoGreen : theme.colours.mayoWhite} !important;
//   border-color: ${({ theme }) => theme.colours.oliveGrey} !important;
//   margin: 5px;
//   font-weight: normal !important;
// `

const DivFlex = styled.div`
  display: flex;
  gap: 1%;
`
const DivImg = styled.div`
  display: flex;
  width: 100%;
  max-width: 200px;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 10px;
`

const Img = styled.img`
  width: 170px;
  height: auto;
`

// const StyledSection = styled.div`
//   border: 2px solid #bdbbbb;
//   padding: 0 10px;
//   border-radius: 5px;
// `

const FieldSet = styled.fieldset`
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
`

const Legend = styled.legend`
  width: 80px;
  font-size: 0.8em;
  padding: 0 5px;
`

// const AdminFieldSet = styled.fieldset`
//   border: 1px solid #e8e8e8;
//   border-radius: 5px;
//   width: 50%;
//   display: flex;
//   justify-content: space-between;
//   padding: 0 10px;
//   margin-top: 15px;
// `

// const AdminCodeLegend = styled.legend`
//   width: 120px;
//   font-size: 0.8em;
//   padding: 0 5px;
// `

const SaveButton = styled.div`
  float: right;
`

const InnerDiv = styled.div`
  background-color: ${({ theme }) => theme.colours.lettuceGreen} !important;
`

export default Settings
