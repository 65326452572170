const { convertArrayToCSV } = require('convert-array-to-csv')

const arrayToCSV = data => {
  data = data.map(item => {
    return {
      _id: item._id,
      sku: item.sku,
      title: item.title,
      type: item.type,
      size: item.size,
      CustomerPrice: item.price,
      GLN: item.GLN,
      SupplierPrice: item.OMSPrice,
      UoM: item.UoM,
      pricedBy: item.pricedBy || '',
      hasVat: item.hasVat,
      supplier: item.supplier ? item.supplier.title : '',
      cloudinaryImageId: item.cloudinaryImageId,
      archive: item.isDeleted,
    }
  })

  const csvFromArrayOfObjects = convertArrayToCSV(data)
  return csvFromArrayOfObjects
}

export { arrayToCSV }
