import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'

const initialState = {
  totalSaleWeek: null,
  GP: '',
  totalMiscCosts: '',
  totalSaleAmount: '',
  dateRange: null,
  customMode: false,
  isLoading: false,
}

const weeklySlice = createSlice({
  name: 'sale',
  initialState,
  reducers: {
    setGrossProfit: (state, action) => {
      state.GP = action.payload
    },
    setTotalMiscCosts: (state, action) => {
      state.totalMiscCosts = action.payload
    },
    setTotalSaleAmount: (state, action) => {
      state.totalSaleAmount = action.payload
    },
    setTotalSaleWeek: (state, action) => {
      state.totalSaleWeek = action.payload
    },
    setCustomMode: (state, action) => {
      state.customMode = action.payload
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  setTotalSaleWeek,
  setCustomMode,
  setDateRange,
  setIsLoading,
  setGrossProfit,
  setTotalMiscCosts,
  setTotalSaleAmount,
} = weeklySlice.actions

export const fetchGrossProfitAndSales =
  (from, to, venueId) => async dispatch => {
    dispatch(setIsLoading(true))
    return API.get(
      'api',
      `gross-profit/sales-per-day?fromDate=${from}&toDate=${to}&venue=${venueId}`,
    )
      .then(data => {
        const formatedData = data?.salesPerDay?.map((obj, index) => ({
          ...obj,
          date: dayjs(obj.date).format('YYYY-MM-DD'),
          day: dayjs(obj.date).format('dddd'),
          key: 'item-' + index,
        }))
        dispatch(setTotalSaleWeek(formatedData || []))
        dispatch(setGrossProfit(data?.gpp ?? ''))
        dispatch(setTotalMiscCosts(data?.totalMiscCosts ?? ''))
        dispatch(setTotalSaleAmount(data?.totalSaleAmount ?? ''))
        dispatch(setIsLoading(false))
        return null
      })
      .catch(error => {
        dispatch(setIsLoading(false))
        dispatch(setTotalSaleWeek([]))
        dispatch(setGrossProfit(''))
        console.error(error)
        return null
      })
  }

export const sendSalesOrPurchaseData =
  (venueId, body, dateRange) => async dispatch => {
    dispatch(setIsLoading(true))
    return API.post('api', `gross-profit/${venueId}`, {
      body,
    })
      .then(() => {
        dispatch(setIsLoading(false))
        dispatch(
          fetchGrossProfitAndSales(
            dateRange.startDate,
            dateRange.endDate,
            venueId,
          ),
        )
        return null
      })
      .catch(error => {
        dispatch(setIsLoading(false))
        console.error(error)
        return null
      })
  }

export default weeklySlice
